import { Button, type ButtonProps } from '#app/components/ui/button.tsx'
import { Link } from '@remix-run/react'

export default function ButtonLink({
	children,
	to,
	...props
}: { to: string } & ButtonProps) {
	return (
		<Link to={to}>
			<Button {...props}>{children}</Button>
		</Link>
	)
}
